
import { Link, graphql } from "gatsby";
import React, { useState, useEffect } from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { useLunr } from 'react-lunr'
import _ from 'lodash';

export default ({data}) => {
  const posts = data.allWpPost.nodes;

  return (
    <Layout  title={'Arakatu'}>
      <Seo title="Arakatu" />
      <h1 className="text-4xl">
        Arakatu
      </h1>
      <span>Egun, {posts.length} artikulu dauzkagu Gizapedian</span>
        <ol className="my-5 w-full">
          {posts.map((post, index) => (
            <li key={`post${index}`} className="inline-block w-full md:w-1/4 pr-2"><Link to={`/${post.slug}`}>{post.title}</Link></li>
          ))}
        </ol>
    </Layout>
  );
}
export const query = graphql`
  query {
    allWpPost(
      limit: 99999
      sort: { fields: title, order: ASC }
     ) {
      nodes {
        title
        excerpt
        slug
        date(formatString: "MMMM DD, YYYY")
      }
     }
  }`